<template>
    <div class="publish">
        <editor v-model="value" :min-height="192"/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: ''
        }
    },
}
</script>
<style lang="scss" scoped>
    .publish {
        width: 1000px;
        min-height: 620px;
        background-color: #FFF;
        border-radius: 10px;
        padding: 16px;
    }
</style>