<template>
    <div class="publish-comment">
        <textarea class="publish-comment__textarea" :placeholder="placeholder" v-model="comment.content"></textarea>
        <div class="publish-comment__btn-area">
            <img class="publish-comment__add-picture" src="@/assets/images/add-picture.svg" alt="">
            <span class="publish-comment__count">0/{{ inputCount }}</span>
            <button class="publish-comment__btn" @click="publishComment">发送</button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'publish-comment',
    props: {
        comment: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    content: '',
                    username: '',
                    avatar: '',
                    time: '',
                    reply: [],
                    replyNum: 0
                }
            }
        },
        placeholder: {
            type: String,
            default: '',
        },
        inputCount: {
            type: Number,
            default: 100,
        }
    },
    data() {
        return {

        }
    },
    methods: {
        publishComment() {
            this.$emit('publishComment', this.comment);
        }
    },
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
.publish-comment {
        padding: 8px 12px;
        background-color: #F2F3F5;
        resize: none;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 16px;
        color: #515767;
    .publish-comment__textarea {
        min-height: 56px;
        background: none;
        width: 100%;
    }

    .publish-comment__btn-area {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .publish-comment__add-picture {
        width: 20px;
        margin-right: 16px;
    }

    .publish-comment__count {
        margin-right: 16px;
        font-size: 14px;
        color: #515767;
    }

    .publish-comment__btn {
        padding: 0px 12px;
        border-radius: 5px;
        line-height: 32px;
        cursor: pointer;
        color: #FFF;
        background-color: #B2E2E1;
    }
}</style>