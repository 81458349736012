<template>
  <div class="user-content">
    <div class="main-content">
      <user-info></user-info>
      <div class="index-main shadow">
        <div class="list__header">
          <ul class="list__nav">
            <li
              class="list__nav-item"
              :class="{ 'list__nav-item--active': item.id == navId }"
              v-for="item in navs"
              :key="item.title"
              @click="changeNavType(item)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>

        <artic-list v-if="navId === 1" :list="list"></artic-list>
        <div
          v-else-if="navId === 2"
          class="publish-item-wrap shadow"
          v-for="(item, index) in publishList"
          :key="item.msg_id"
        >
          <publish-item
            :item.sync="publishList[index]"
            :actors="actors"
          ></publish-item>
        </div>
      </div>
    </div>
    <div class="right-section">
      <about-author class="about-author" :info="articDetail"></about-author>
    </div>
  </div>
</template>
<script>
import userInfo from "@/components/business/user/userInfo.vue";
import aboutAuthor from "@/components/business/detail/aboutAuthor.vue";
export default {
  data() {
    return {
      value: "",
      navs: [
        {
          id: 1,
          title: "杂谈",
          type: 1,
        },
        {
          id: 2,
          title: "闲聊",
          type: 2,
        },
        {
          id: 3,
          title: "收藏夹",
          type: 3,
        },
        {
          id: 4,
          title: "关注",
          type: 4,
        },
      ],
      navId: 1,
      list: [
        {
          item_type: 2,
          item_info: {
            article_id: 1,
            author_user_info: {
              user_id: 1,
              user_name: "不学就考不上",
            },

            article_info: {
              title: "这是一级标题，加粗，16px",
              brief_content:
                "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
              comment_count: 100,
              likeCountField: 11,
              ctime: "2023-12-20 19:23",
              cover_image: "",
            },
            tags: [
              {
                id: 1,
                tag_name: "标签1",
              },
              {
                id: 2,
                tag_name: "标签2",
              },
              {
                id: 3,
                tag_name: "标签3",
              },
            ],
          },
        },
      ],
      publishList: [
        {
          title: "这是标题",
          content: "这是内容",
          screenshot: "@/assets/images/logo.png",
          author_user_info: {
            user_id: 1,
            job_title: "测试工程师",
            company: "微软",
          },
          msg_Info: {
            ctime: "2小时前",
            content: "这只是浅浅发布一下内容",
            pic_list: ["@/assets/images/logo.png"],
            digg_count: 100,
            comment_count: 100,
            url_title: "百度官网",
            url: "www.baidu.com",
            url_pic: "@/assets/images/logo.png",
          },
          topic: {
            topic_id: 1,
            title: "自定义话题",
          },

          user_interact: {
            is_follow: true,
            is_digg: false,
          },
        },
        {
          title: "这是标题",
          content: "这是内容",
          screenshot: "@/assets/images/logo.png",
          author_user_info: {
            user_id: 1,
            job_title: "测试工程师",
            company: "微软",
          },
          msg_Info: {
            ctime: "2小时前",
            content: "这只是浅浅发布一下内容",
            pic_list: ["@/assets/images/logo.png", "@/assets/images/logo.png"],
            digg_count: 100,
            comment_count: 100,
            url_title: "百度官网",
            url: "www.baidu.com",
            url_pic: "@/assets/images/logo.png",
          },
          topic: {
            topic_id: 1,
            title: "自定义话题",
          },

          user_interact: {
            is_follow: true,
            is_digg: false,
          },
        },
        {
          title: "这是标题",
          content: "这是内容",
          screenshot: "@/assets/images/logo.png",
          author_user_info: {
            user_id: 1,
            job_title: "测试工程师",
            company: "微软",
          },
          msg_Info: {
            ctime: "2小时前",
            content: "这只是浅浅发布一下内容",
            pic_list: [
              "@/assets/images/logo.png",
              "@/assets/images/logo.png",
              "@/assets/images/logo.png",
            ],
            digg_count: 100,
            comment_count: 100,
            url_title: "百度官网",
            url: "www.baidu.com",
            url_pic: "@/assets/images/logo.png",
          },
          topic: {
            topic_id: 1,
            title: "自定义话题",
          },

          user_interact: {
            is_follow: true,
            is_digg: false,
          },
        },
      ],
      articDetail: {
        author_user_info: {
          user_id: 1,
        },
        author_user_info: {
          avatar_large: "",
          user_name: "测试",
          level: 10,
          job_title: "后端",
          company: "微亮",
        },
        article_info: {
          brief_content:
            "1. 敢于超前研发。树立首创精神,敢为人先,形成差异化竞争力,抵御风险,赢得优势。 2. 加强技术攻关。成立专门团队,进行大量试验,不懈探索分析,不断尝试改进。 3. 市场需求导",
          ctime: "2023-10-26 17:23",
          view_count: 100,
          cover_image: "",
          title: "这是测试标题",
        },
        user_interact: {
          is_follow: false,
        },
        tags: [
          {
            tag_id: 1,
            tag_name: "ce",
          },
          {
            tag_id: 2,
            tag_name: "ae",
          },
          {
            tag_id: 3,
            tag_name: "se",
          },
        ],
      },
    };
  },

  methods: {
    changeNavType(item) {
      if (item.id && this.navId !== item.id) {
        this.navId = item.id;
      }
    },
  },

  metaInfo: {
    title: "###的个人主页",
    titleTemplate: null,
  },

  components: {
    "user-info": userInfo,
    "about-author": aboutAuthor,
  },
};
</script>
<style lang="scss" scoped>
.user-content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  .main-content {
    position: relative;
    left: 160px;
    width: 720px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .index-main {
      background: #fff;
      border-radius: 10px;
      padding: 20px;
    }

    .list__header {
      border-bottom: 1px solid $color-third;
    }

    .list__nav {
      display: flex;
      align-items: center;

      .publish {
        margin-left: auto;
        font-size: $font-size-Level6;
        padding: 4px 10px;
        background-color: $color-primary;

        border-radius: 5px;
        cursor: pointer;

        button {
          color: $font-color-secondary;
          background-color: $color-primary;

          span {
            font-weight: 900;
            font-size: $font-size-Level6;
            margin-right: 7px;
          }
        }
      }

      .list__nav-item {
        height: 50px;
        display: inline-block;
        font-size: $font-size-Level5;
        color: $font-color-primary;
        cursor: pointer;
        padding: 0 23px;
        position: relative;

        line-height: 50px;

        &:hover,
        &.list__nav-item--active {
          color: $color-primary;
        }

        &.list__nav-item--active::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          width: 34px;
          height: 8px;
          background: $color-primary;
          left: 30%;
          transform: scaleY(0.5);
        }
      }
    }
  }

  .right-section {
    width: 300px;
    height: 200px;
  }
}
</style>
