import { login, getUserInfo, logout } from '@/api/index'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
    namespaced: true,
    state: {
        token: getToken(),
        userId: '',
        nickName: '',
        avatar: '',
    },
    getters: {
        getState(state) {
            return state;
        }
    },
    mutations: {
        SET_TOKEN: (state, data) => {
            state.token = data
        },
        SET_USER_ID: (state, data) => {
            state.userId = data
        },
        SET_NICK_NAME: (state, data) => {
            state.nickName = data
        },
        SET_AVATAR: (state, data) => {
            state.avatar = data
        },
    },
    actions: {
        // 登陆 
        Login({ commit }, userInfo) {
            const account = userInfo.account.trim();
            const password = userInfo.password;
            let data = {}
            if (userInfo.account) {
                data.account = account;
                data.password = password;
            }
            return new Promise((resolve, reject) => {
                login(data).then(response => {
                    const data = response.data;
                    setToken(data.token);
                    commit('SET_TOKEN', data.token);
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            });
        },
        // 获取用户信息
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(res => {
                    const user = res.data.user
                    const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/default-user.svg") : user.avatar;
                    commit('SET_USER_ID', user.userId)
                    commit('SET_NICK_NAME', user.nickName)
                    commit('SET_AVATAR', avatar)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // // 退出系统
        LogOut({ commit, state }) {
            return new Promise((resolve, reject) => {
                logout(state.token).then(() => {
                    commit('SET_TOKEN', '')
                    removeToken()
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}

export default user