<template>
    <div>
        <div class="wrapper">
            <div class="login-content">
                <div class="login-title">
                    <img src="@/assets/images/logo.png" alt="">
                    欢迎使用囊中对比
                </div>
                <div class="account">
                    <input v-model="account" type="text" :class="{ 'error': accountError }" placeholder="请输入手机号或囊友号">
                </div>
                <div class="password">
                    <input type="password" placeholder="请输入密码">
                </div>
                <div class="login-alert ">
                    <a href="javascript:;" @click="forgetPassword">忘记密码？</a>
                </div>
                <div class="operate">
                    <button @click="login">登录</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {    
        name: "AccountLoginPage",
        data(){
            return {
                loginForm: {
                    account: '',
                    password: ''
                },
                redirect: undefined,
            }
        },
        watch: {
            $route: {
            handler: function(route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true
            }
        },
        methods:{
            login(){
                //跳转到首页
                this.$store.dispatch("user/Login", this.loginForm).then(() => {
                    this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
                }).catch(() => {
                });
            },
            forgetPassword(){
                //跳转到忘记密码页面
                this.$router.push("/login/forget");
            }
        }
    }

</script>
<style lang="scss" scoped>
    .wrapper{
        background-image: url(~@/assets/images/login-bgc.svg) ;
        background-size: 100%;
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items : center;
        padding: 0 20px;
        .login-content{
            border-radius: 10px;
            box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
            max-width: 500px;
            width: 100%;
            padding: 60px 0px;
            background-color: $background-color;
            display: flex;
            align-items:center;
            flex-direction: column;
            // align-items : center;
            .login-title {
                font-size: $font-size-Level4;
                line-height: 48px;
                img {
                    width: 36px;
                }
            }
            .account, .password {
                input:focus {
                    border: 1px solid $color-primary;
                };
            }
            .account {
                margin-top: 24px;
                input {
                    background-color: $background-color-secondary;
                    color: $font-color-fourth;
                    width: 320px;
                    height: 48px;
                    border-radius: 25px;
                    padding: 0 24px;
                }
            }
            .password {
                margin-top: 32px;
                input {
                    background-color: $background-color-secondary;
                    color: $font-color-fourth;
                    width: 320px;
                    height: 48px;
                    border-radius: 25px;
                    padding: 0 24px;
                }
            }
            .login-alert {
                width: 70%;
                margin-top: 24px;
                display:flex;
                flex-direction: row;
                justify-content: flex-end;   
                a {
                    color: $font-color-third;
                }
            }
            .operate {
                margin-top: 32px;
                display: flex;
                justify-content: center;
                width: 320px;
                button {
                    width: 160px;
                    padding: 9px 32px;
                    border-radius: 25px;
                    border: none;
                    color: $font-color-secondary;
                }
                button:nth-child(1) {
                    background-color: $color-secondary;
                }
                button:nth-child(1):hover {
                    background-color: $color-primary;
                }
            }
            .login-aggrement {
                margin-top: 66px;
                a {
                    color: $font-color-third;
                }
            }
        }
    }
</style>