<template>
    <div>
        <div class="wrapper">
            <div class="login-content">
                <div class="login-title">
                    <img src="@/assets/images/logo.png" alt="">
                    欢迎使用囊中对比
                </div>
                <div class="user-number">
                    囊友号：{{ userNumber }}
                </div>
                <div class="phone">
                    <input v-model="phone" type="tel" :class="{ 'error': phoneError }" placeholder="绑定手机号"
                        @blur="validatePhone" maxlength="11">
                    <span v-if="phoneError" class="error-message">手机号码格式不正确</span>
                </div>
                <div class="password">
                    <input type="password" v-model="password" @input="validatePassword" placeholder="设置密码">
                    <span  v-if="passwordError" class="error-message">{{ passwordErrorMessage }}</span>
                </div>
                <div class="operate">
                    <button>取消</button>
                    <button @click="bindInfo">绑定</button>
                </div>
                <div class="login-aggrement">
                    登录/注册表示同意
                    <a href="javascript:;">隐私政策</a>
                    和
                    <a href="javascript:;">服务条款</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { bindInfo } from "@/api/index";
export default {
    name: "LoginPage",
    data() {
        return {
            userNumber: '',
            isBind: null,
            phone: '',
            phoneError: false,
            password: '',
            passwordError: false,
            passwordErrorMessage: ''
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(from);
        if(from.path === '/login/code') {
            next((vm) => {});
        } else {
            next('/login/code');
        }
        
    },
    created() {
        this.userNumber = this.$route.query.userNumber;
    }
    ,
    methods: {
        validatePhone(event) {
            const value = event.target.value;
            const phonePattern = /^1[3-9]\d{9}$/;
            this.phoneError = !phonePattern.test(value);
        },
        validatePassword(event) {
            const value = event.target.value;
            this.passwordError = !this.isValidPassword(value);
            if (this.passwordError) {
                this.passwordErrorMessage = '密码至少6位，且必须包含至少一个数字和至少一个字母'; // 自定义错误消息
            } else {
                this.passwordErrorMessage = '';
            }
        },
        isValidPassword(password) {
            // 假设密码必须包含数字、字母和特殊字符，且长度在6-16之间
            const pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
            return pattern.test(password);
        },
        bindInfo() {
            if(!this.phone || this.phoneError) {
                this.$message.warning('请输入正确的手机号');
                return;
            }
            if(!this.password || this.passwordError) {
                this.$message.warning('请输入符合规则的密码');
                return;
            }
            const data = {
                userNumber: this.userNumber,
                phone: this.phone,
                password: this.password
            };
            bindInfo(data).then(res => {
                if (res.code === 200) {
                    this.$message.success('绑定成功');
                    this.$router.push({ path: '/community/home' });
                }
            });
        }
    }
}

</script>
<style lang="scss" scoped>
.wrapper {
    background-image: url(~@/assets/images/login-bgc.svg);
    background-size: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .login-content {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
        max-width: 500px;
        width: 100%;
        padding: 60px 0px;
        background-color: $background-color;
        display: flex;
        align-items: center;
        flex-direction: column;

        // align-items : center;
        .login-title {
            font-size: $font-size-Level4;
            line-height: 48px;

            img {
                width: 36px;
            }
        }

        .user-number {
            margin-top: 58px;
            line-height: 48px;
            background-color: $background-color-secondary;
            color: $font-color-fourth;
            width: 320px;
            height: 48px;
            border-radius: 25px;
            padding: 0 24px;
        }

        .phone,
        .password {
            input:focus {
                border: 1px solid $color-primary;
            }

            ;
        }

        .phone {
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            input {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 320px;
                height: 48px;
                border-radius: 25px;
                padding: 0 24px;
                text-align: center;
            }

            .error {
                border-color: red;
            }

            .error-message {
                color: red;
            }
        }

        .password {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            input {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 320px;
                height: 48px;
                border-radius: 25px;
                padding: 0 24px;
                text-align: center;
            }
            .error-message {
                color: red;
                font-size: 12px;
            }
        }

        .operate {
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            width: 320px;

            button {
                padding: 9px 32px;
                border-radius: 25px;
                border: none;
                color: $font-color-secondary;
            }

            button:nth-child(1) {
                color: $font-color-fourth;
            }

            button:nth-child(2) {
                background-color: $color-primary;
            }
        }

        .login-aggrement {
            margin-top: 66px;

            a {
                color: $font-color-third;
            }
        }
    }
}</style>