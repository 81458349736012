<template>
    <div>
        <div class="wrapper">
            <div class="login-content">
                <div class="login-title">
                    重置密码
                </div>

                <div class="login-code">
                    <img src="@/assets/images/mini-code.svg" alt="">
                </div>
                <div class="login-desc">
                    请先微信扫码授权
                </div>
                <div class="phone">
                    <input type="text" placeholder="请输入手机号或囊友号">
                </div>

                <div class="password">
                    <input type="password" placeholder="请输入新密码">
                </div>
                <div class="password">
                    <input type="password" placeholder="请确认密码">
                </div>
                <div class="operate">
                    <button>确认重置</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ForgetPasswordPage",
    data() {
        return {
            //
        }
    },
    methods: {
    }
}

</script>
<style lang="scss" scoped>
.wrapper {
    background-image: url(~@/assets/images/login-bgc.svg);
    background-size: 100%;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .login-content {
        border-radius: 10px;
        box-shadow: 0px 0px 5px 0px rgba(129, 129, 129, 0.25);
        max-width: 500px;
        width: 100%;
        padding: 60px 0px;
        background-color: $background-color;
        display: flex;
        align-items: center;
        flex-direction: column;

        // align-items : center;
        .login-title {
            font-size: $font-size-Level4;
        }

        .login-desc {
            margin-top: 32px;
            font-size: $font-size-primary;
            color: $font-color-primary;
        }

        .phone,
        .password {
            input:focus {
                border: 1px solid $color-primary;
            }

            ;
        }

        .phone {
            margin-top: 24px;

            input {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 320px;
                height: 48px;
                border-radius: 25px;
                padding: 0 24px;
            }
        }

        .password {
            margin-top: 32px;
            display:none;
            input {
                background-color: $background-color-secondary;
                color: $font-color-fourth;
                width: 320px;
                height: 48px;
                border-radius: 25px;
                padding: 0 24px;
            }
        }

        .login-code {
            margin-top: 32px;
        }

        .operate {
            margin-top: 32px;
            display: flex;
            justify-content: center;
            width: 320px;

            button {
                width: 160px;
                padding: 9px 32px;
                border-radius: 25px;
                border: none;
                color: $font-color-secondary;
            }

            button:nth-child(1) {
                background-color: $color-secondary;
            }

            button:nth-child(1):hover {
                background-color: $color-primary;
            }
        }
    }
}</style>