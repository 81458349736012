<template>
    <div>
        <!-- 头部 -->
        <header class="header" :style="headerStyle">
            <div class="header-wrapper">
                <div class="logo" @click="toHome">
                    <img src="@/assets/images/logo.png" alt="">
                    <img src="@/assets/images/logo-font.png" alt="">
                </div>
                <div class="nav">
                    <a href="https://help.srnz.net">帮助中心</a>
                    <a href="https://help.srnz.net/base/download">下载</a>
                    <a href="https://help.srnz.net/version/introduce">会员</a>
                    <a href="https://help.srnz.net/about/update">更新日志</a>
                </div>
                <!-- 登录按钮 -->
                <div class="login">
                    <button :style="loginButtonStyle" @click="handleEnter" @mouseover="handleLoginHover"
                        @mouseout="handleMouseOut">{{ isLogin ? '进入囊中' : '登录/注册' }}</button>
                </div>
                <div class="menu" @click="handleMenu">
                    <img src="@/assets/images/menu-icon.svg" alt="">
                </div>
                <el-card class="menu-box" v-show="isShowMenu">
                    <a href="https://help.srnz.net">帮助中心</a>
                    <a href="https://help.srnz.net/base/download">下载</a>
                    <a href="https://help.srnz.net/version/introduce">会员</a>
                    <a href="https://help.srnz.net/about/update">更新日志</a>
                </el-card>
            </div>
        </header>
        <div class="content" :style="contentStyle">
            <div class="content-wrapper">
                <div class="title"><span>做最好用的</span><span>申论学习社区</span></div>
                <div class="desc">久久为功，把申论收入囊中</div>
                <button class="use" @click="handleEnter">立即使用</button>
                <div class="data-desc">
                    <div>
                        <img src="@/assets/images/paper-icon.svg" alt="">
                        <span>
                            <span>{{ statData.paperCount }}</span>
                            <span>试卷</span>
                        </span>
                    </div>
                    <div>
                        <img src="@/assets/images/answer-icon.svg" alt="">
                        <span>
                            <span>{{ statData.answerCount }}</span>
                            <span>答案</span>
                        </span>
                    </div>
                    <div>
                        <img src="@/assets/images/user-icon.svg" alt="">
                        <span>
                            <span>{{ statData.userCount }}</span>
                            <span>用户</span>
                        </span>
                    </div>
                    <div>
                        <img src="@/assets/images/upload-icon.svg" alt="">
                        <span>
                            <span>{{ statData.addAnswerCount }}</span>
                            <span>上传</span>
                        </span>
                    </div>
                </div>
                <div class="device-title">多平台支持，随时学习</div>
                <div class="device-desc">囊中正逐步覆盖主流平台，多设备数据自动同步</div>
                <div class="platform-pic">
                    <div>
                        <img src="@/assets/images/web.svg" alt="">
                        <span>WEB网页</span>
                    </div>
                    <div>
                        <img src="@/assets/images/miniapp.svg" alt="">
                        <span>小程序</span>
                    </div>
                    <div>
                        <img src="@/assets/images/ios.svg" alt="">
                        <span>iPhone/iPad</span>
                    </div>
                    <div>
                        <img src="@/assets/images/android.svg" alt="">
                        <span>Android</span>
                    </div>
                </div>
                <div class="device-img">
                    <img src="@/assets/images/device.svg" alt="">
                </div>
                <!-- <div class="function">
                    <div class="function-item">
                        <div class="function-desc">
                            <div class="function-title">一键搜题</div>
                            <div class="function-doc">搜罗历年申论真题预置成库
                                实现一键找到各地答案</div>
                        </div>
                        <div class="function-pic-list">
                            <div class="function-pic-list-left"></div>
                            <div class="function-pic-list-right">
                                <div class="function-pic">1</div>
                                <div class="function-pic">2</div>
                                <div class="function-pic">3</div>
                                <div class="function-pic">4</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="comments">
                    <div>和千万囊友一起学习、共同进步</div>
                    <img src="@/assets/images/comments.svg" alt="">
                </div>
            </div>

        </div>

        <div class="footer-banner">
            <div class="title">
                进入囊中，开启沉浸式申论学习
            </div>
            <button class="use" @click="handleEnter">立即使用</button>
        </div>
        <footer>
            <div class="info-wrapper">
                    <div class="logo">
                    <img src="@/assets/images/logo.png" alt="">
                    <a href="https://srnz.net">囊中对比</a>
                </div>
                <div>
                    <span>产品</span>
                    <a href="https://help.srnz.net/base/download">小程序端</a>
                    <a href="https://help.srnz.net/base/download">苹果设备端</a>
                    <a href="https://help.srnz.net/base/download">安卓设备端</a>
                </div>
                <div>
                    <span>服务</span>
                    <a href="https://help.srnz.net">帮助中心</a>
                    <a href="https://help.srnz.net/service/agreement">服务协议</a>
                    <a href="https://help.srnz.net/service/privicy">隐私政策</a>
                </div>
                <div>
                    <span>联系我们</span>
                    <el-popover
                        placement="top"
                        width="200"
                        trigger="hover"
                        content="weiliang@microlight.freeqiye.com">
                        <span slot="reference" style="cursor: pointer;">官方邮箱</span>
                    </el-popover>
                    <el-popover
                    placement="right"
                    width="200"
                    trigger="hover"
                    >
                    <img src="@/assets/images/xiaohongshu.png" alt="公众号" style="width:100%;height:auto;">
                    <span slot="reference" style="cursor: pointer;">小红书</span>
                    </el-popover>
                    <el-popover
                    placement="right"
                    width="200"
                    trigger="hover"
                    >
                    <img src="@/assets/images/gongzhonghao.png" alt="公众号" style="width:100%;height:auto;">
                    <span slot="reference" style="cursor: pointer;">公众号</span>
                    </el-popover>
                    
                </div>

                </div>
                <div class="service-wrapper">
                    <span>©2023-2024 囊中对比</span>
                    <img src="@/assets/images/wang-logo.png" alt="" style="width:16px;height:auto;">
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011502011993">苏公网安备 32011502011993号</a>
                    <a href="http://beian.miit.gov.cn/">苏ICP备2024064100号-2</a>
                </div>
        </footer>
    </div>
</template>
<script>
import store from '@/store'
import { getStatData } from "@/api/index";
export default {
    name: 'HomePage',
    data() {
        return {
            scroll: false,
            isLogin: false,
            activeIndex:0,
            statData: {
                paperCount: 0,
                answerCount: 0,
                userCount: 0,
                addAnswerCount: 0
            },
            headerStyle: {
                animation: ''
            },
            contentStyle: {
                animation: ''
            },
            loginButtonStyle: {

            },
            popoverVisible: false,
            isShowMenu: false,
        }
    },
    mounted() {
        this.getStatData();
        window.addEventListener("scroll", this.handleScroll);
        let userId = store.getters['user/getState'].userId;
        if(userId) {
            this.isLogin = true;
        }
    },
    methods: {
        toHome() {
            this.$router.push("/home")
        },
        handleMenu() {
            this.isShowMenu = !this.isShowMenu;
        },
        handleLoginHover() {
            if (!this.scroll) {
                this.loginButtonStyle = {
                    backgroundColor: 'rgba(157, 157, 166, 0.3)'

                }
            } else {
                this.loginButtonStyle.backgroundColor = '#46B4B1';
            }

        },
        handleMouseOut() {
            if (!this.scroll) {
                this.loginButtonStyle = {
                    backgroundColor: 'rgba(157, 157, 166, 0.15)'
                }
            } else {
                this.loginButtonStyle.backgroundColor = '#69b1ae';
            }
        },
        getStatData() {
            getStatData().then(res => {
                this.statData = res.data;
            });
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            var navAnimationStyle = undefined;
            var contentAnimationStyle = undefined;
            if (scrollTop) {
                this.scroll = true;
                navAnimationStyle = 'navExpand 0.5s linear 0s 1 normal forwards';
                contentAnimationStyle = 'contentChangeStatus2 0.5s linear 0s 1 normal forwards';
                this.loginButtonStyle.backgroundColor = '#69b1ae';
                this.loginButtonStyle.color = '#fff';

            } else {
                this.scroll = false;
                navAnimationStyle = 'navNotExpand 0.5s linear 0s 1 normal forwards';
                contentAnimationStyle = 'contentChangeStatus1 0.5s linear 0s 1 normal forwards';
                this.loginButtonStyle.backgroundColor = 'rgba(157, 157, 166, 0.15)';
                this.loginButtonStyle.color = '#333';
                this.$forceUpdate();
            }

            this.headerStyle.animation = navAnimationStyle;
            this.contentStyle.animation = contentAnimationStyle;
        },
        handleEnter() {
            // 判断是否已登录
            if (this.isLogin) {
                this.$router.push("/community/home");
            } else {
                this.$router.push("/login/code");
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>
<style lang="scss">
@media (max-width: 768px) {
    .data-desc, .platform-pic {
        flex-wrap: wrap;
        justify-content: space-around;
        div {
            width: calc(50% - 10px) !important; // 计算宽度以适应两列布局，并留出适当的间距
            margin-bottom: 32px !important; // 每个元素底部添加间距
        }
    }
    .header-wrapper,
    .content-wrapper,
    .info-wrapper {
        padding: 0 20px !important;
    }
    .nav {
        display: none !important;
    }
    .menu {
        display: block !important;
    }
    .login  {
        margin-left: auto;
    }
}
header {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 96px;
    background-color: $background-color-secondary;
    left: 0;
    top: 0;
    z-index: 10;
}

@keyframes navExpand {
    to {
        height: 70px;
        background-color: $background-color;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 24px;
    }
}

@keyframes navNotExpand {
    from {
        height: 70px;
        background-color: $background-color;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 24px;
    }

    to {
        height: 96px;
        background-color: $background-color-secondary;
        box-shadow: none;
    }
}

@keyframes contentChangeStatus1 {
    from {
        margin-top: 70px;
    }

    to {
        margin-top: 96px;
    }
}

@keyframes contentChangeStatus2 {
    to {
        margin-top: 70px;
    }
}

.header-wrapper,
.content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    padding: 0 40px;
}

.nav {
    position: relative;
    margin-left: auto;
}

.nav a {
    display: inline-block;
    margin-right: 48px;
    font-size: $font-size-primary;
}



.login button {
    width: 115px;
    display: inline-block;
    font-size: $font-size-Level6;
    border: none;
    background-color: rgba(157, 157, 166, 0.15);
    border-radius: 8px;
    height: 40px;
}

.logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img:first-child {
        width: 32px;
        margin-right: 16px;
    }

    img:last-child {
        width: 108px;
    }
}

.menu {
    display: none;
    margin-left: 22px;
    img {
        width: 24px;
    }
}
.menu-box {
    position: absolute;
    right: 20px;
    top: 70px;
    display: flex;
    flex-direction: column;
    a {
        display: block;
        font-size: $font-size-primary;
        padding: 12px 54px 12px 18px;
        font-weight: bold;
    }
}

.content {
    margin-top: 96px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $background-color-secondary;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url(~@/assets/images/color-bgc.svg);
    background-repeat: no-repeat;
    background-position: 50% 0%;

    .title {
        margin-top: 20px;
        background: linear-gradient(120deg, #5D4CC6 45.45%, #1CAECE 65.04%, #37A9C2 84.04%, #50CCB6 102.44%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        display: flex;
        flex-wrap: wrap; 
        span:first-child {
            font-size: $font-size-Level1;
            font-weight: bold;
            background-color: $font-color-primary;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            flex-basis: 100%; 
        }

        span:last-child {
            font-size: $font-size-Level1;
            font-weight: bold;
            flex-basis: 100%; 
        }
    }


    .desc {
        margin-top: 20px;
        font-size: $font-size-Level3;
        display: flex;
        text-align: center;
    }

    .use {
        margin-top: 49px;
        padding: 13px 36px;
        border-radius: 10px;
        background-color: $color-primary;
        border: none;
        color: $font-color-secondary;
        font-size: $font-size-Level5;
    }

    .use:hover {
        transform: scale(1.05);
    }

    .data-desc {
        width: 100%;
        display: flex;
        justify-content: space-around;
        div {
            display: flex;
            img {
                width: 44px;
                margin-right: 12px;
            }
            span {
                display: flex;
                flex-direction: column;

                span:first-child {
                    font-size: $font-size-Level3;
                }

                span:last-child {
                    font-size: $font-size-Level4;
                    color: $font-color-fourth;
                }
            }
        }

        margin-top: 136px;

    }

    .device-title {
        margin-top: 132px;
        font-size: $font-size-Level2;
        text-align: center;
    }

    .device-desc {
        margin-top: 32px;
        font-size: $font-size-Level4;
        text-align: center;
    }



    .platform-pic {
        width: 100%;
        margin-top: 88px;
        display: flex;
        justify-content: space-around;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                width: 132px;
            }

            span {
                margin-top: 24px;
                font-size: $font-size-Level6;
            }
        }

        div img:hover {
            transform: scale(1.05);
            box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 24px;
            cursor: pointer;
        }
    }

    .device-img {
        margin-top: 88px;
        img {
            width: 100%;
        }
    }

}

.function {
    width: 100%;

    .function-item {
        margin-top: 110px;
        position: relative;
        display: flex;
        height: 723px;

        .function-desc {
            position: absolute;
            width: 40%;
            height: 723px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .function-title {
                font-size: $font-size-Level3;
            }

            .function-doc {
                margin-top: 24px;
                font-size: $font-size-Level5;
            }
        }

        .function-pic-list {
            width: 100%;
            display: flex;
            overflow: scroll;

            .function-pic-list-left {
                width: 40%;
                position: relative;
            }

            .function-pic-list-right {

                width: 60%;
            }

            .function-pic {
                height: 723px;
                background-color: $color-primary;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }

    .function-pic-list::-webkit-scrollbar {
        display: none;
        /*隐藏滚动条*/
    }
}

.comments {
    margin-top: 111px;
    background-color: $background-color-secondary;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
        margin-bottom: 48px;
        font-size: $font-size-Level4;
        text-align: center;
    }
    img {
        width: 100%;
    }
}

.footer-banner {
    height: 360px;
    background-image: url(~@/assets/images/footer-bgc.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        font-size: $font-size-Level2;
        color: $font-color-secondary;
        text-align: center;
    }

    button {
        color: $color-primary;
        padding: 12px 40px;
        background-color: $background-color;
        border-radius: 50px;
        border: none;
        margin-top: 100px;
        font-size: $font-size-Level5;
    }

    button:hover {
        transform: scale(1.05);
    }
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-color-secondary;

    .info-wrapper {
        max-width: 1280px;
        width: 100%;
        
        display: flex;
        justify-content: space-between;
        padding: 0 40px;
        div {
            display: flex;
            flex-direction: column;

            span:first-child {
                margin-bottom: 8px;
            }

            a,
            span {
                margin-top: 12px;
                font-size: $font-size-Level7;
            }
        }

        div:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            a {
                height: 36px;
                font-size: $font-size-Level4;
                font-weight: bold;
            }
        }
    }
    .service-wrapper {
        height: 50px;
        display: flex;
        align-items: center;
        a,span {
            font-size: $font-size-Level7;
            margin-right: 12px;
        }
    }
}</style>