<template>
    <div class="user-action">
        <div class="user-action__item">
            <div class="user-action__item-icon">
                <img src="@/assets/images/action-up.svg" alt="">
            </div>
        </div>
        <div class="user-action__item">
            <div class="user-action__item-icon">
                <img src="@/assets/images/action-comment.svg" alt="">
            </div>
        </div>
        <div class="user-action__item">
            <div class="user-action__item-icon">
                <img src="@/assets/images/action-like.svg" alt="">
            </div>
        </div>
        <div class="user-action__item">
            <div class="user-action__item-icon">
                <img src="@/assets/images/action-report.svg" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.user-action {
  display: flex;
  flex-direction: column;
    margin-top: 45px;
 .user-action__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    
}

.user-action__item-icon {
    background-color: #FFF;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      background-color: #ebedef;
    }
}

}
</style>