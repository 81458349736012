<template>
    <div class="body-content">
        <discuss-category></discuss-category>
        <div class="index-container">
            <div class="index-main shadow">
                <div class="list__header">
                    <ul class="list__nav">
                        <li class="list__nav-item" :class="{ 'list__nav-item--active': item.id == navId }"
                            v-for="item in navs" :key="item.title" @click="changeNavType(item)">{{ item.title }}
                        </li>
                        <router-link to="/community/publish" >
                            
                        </router-link>
                        <button class="publish" @click="toPublish()">
                                <span>+</span>发布
                            </button>
                    </ul>
                </div>
                <artic-list :list="list"></artic-list>
            </div>
        </div>
        <div class="right-section">
            <user-data class="user-data"></user-data>
            <tool-box class="tool-box"></tool-box>
            <!-- <news></news> -->
        </div>
    </div>
</template>
<script>
import discussCategory from '@/components/business/discuss/discussCategory.vue';
import userData from '@/components/business/discuss/userData.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
import news from '@/components/business/discuss/news.vue';
import { insertArticleDraft } from '@/api/index';
export default {
    name: "CommunityHomePage",
    data() {
        return {
            navs: [
                {
                    id: 1,
                    title: '最新',
                    type: 1
                },
                {
                    id: 2,
                    title: '最热',
                    type: 2
                },
                {
                    id: 3,
                    title: '关注',
                    type: 3,
                }
            ],
            navId: 1,
            navTypes: [],
            list: [{
                item_type: 2,
                item_info: {
                    article_id: 1,
                    author_user_info: {
                        user_id: 1,
                        user_name: "不学就考不上"
                    },

                    article_info: {
                        title: "这是一级标题，加粗，16px",
                        brief_content: "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
                        comment_count: 100,
                        likeCountField: 11,
                        ctime: "2023-12-20 19:23",
                        cover_image: ''
                    },
                    tags: [
                        {
                            id: 1,
                            tag_name: "标签1"
                        },
                        {
                            id: 2,
                            tag_name: "标签2"
                        },
                        {
                            id: 3,
                            tag_name: "标签3"
                        }
                    ]


                }
            },
            {
                item_type: 2,
                item_info: {
                    article_id: 1,
                    author_user_info: {
                        user_id: 1,
                        user_name: "不学就考不上"
                    },

                    article_info: {
                        title: "这是一级标题，加粗，16px",
                        brief_content: "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
                        comment_count: 100,
                        likeCountField: 11,
                        ctime: "2023-12-20 19:23",
                        cover_image: ''
                    },
                    tags: [
                        {
                            id: 1,
                            tag_name: "标签1"
                        },
                        {
                            id: 2,
                            tag_name: "标签2"
                        },
                        {
                            id: 3,
                            tag_name: "标签3"
                        }
                    ]


                }
            },
            {
                item_type: 2,
                item_info: {
                    article_id: 1,
                    author_user_info: {
                        user_id: 1,
                        user_name: "不学就考不上"
                    },

                    article_info: {
                        title: "这是一级标题，加粗，16px",
                        brief_content: "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
                        comment_count: 100,
                        likeCountField: 11,
                        ctime: "2023-12-20 19:23",
                        cover_image: ''
                    },
                    tags: [
                        {
                            id: 1,
                            tag_name: "标签1"
                        },
                        {
                            id: 2,
                            tag_name: "标签2"
                        },
                        {
                            id: 3,
                            tag_name: "标签3"
                        }
                    ]


                }
            },
            {
                item_type: 2,
                item_info: {
                    article_id: 1,
                    author_user_info: {
                        user_id: 1,
                        user_name: "不学就考不上"
                    },

                    article_info: {
                        title: "这是一级标题，加粗，16px",
                        brief_content: "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
                        comment_count: 100,
                        likeCountField: 11,
                        ctime: "2023-12-20 19:23",
                        cover_image: ''
                    },
                    tags: [
                        {
                            id: 1,
                            tag_name: "标签1"
                        },
                        {
                            id: 2,
                            tag_name: "标签2"
                        },
                        {
                            id: 3,
                            tag_name: "标签3"
                        }
                    ]


                }
            },
            {
                item_type: 2,
                item_info: {
                    article_id: 1,
                    author_user_info: {
                        user_id: 1,
                        user_name: "不学就考不上"
                    },

                    article_info: {
                        title: "这是一级标题，加粗，16px",
                        brief_content: "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
                        comment_count: 100,
                        likeCountField: 11,
                        ctime: "2023-12-20 19:23",
                        cover_image: ''
                    },
                    tags: [
                        {
                            id: 1,
                            tag_name: "标签1"
                        },
                        {
                            id: 2,
                            tag_name: "标签2"
                        },
                        {
                            id: 3,
                            tag_name: "标签3"
                        }
                    ]


                }
            },
            {
                item_type: 2,
                item_info: {
                    article_id: 1,
                    author_user_info: {
                        user_id: 1,
                        user_name: "不学就考不上"
                    },

                    article_info: {
                        title: "这是一级标题，加粗，16px",
                        brief_content: "这是正文，只展示两行，13PXADFADFAdsfadsf范德萨打发手动阀手动阀手动阀手动阀撒旦发射点发射点发射点发射点东方时代顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶顶dd…",
                        comment_count: 100,
                        likeCountField: 11,
                        ctime: "2023-12-20 19:23",
                        cover_image: ''
                    },
                    tags: [
                        {
                            id: 1,
                            tag_name: "标签1"
                        },
                        {
                            id: 2,
                            tag_name: "标签2"
                        },
                        {
                            id: 3,
                            tag_name: "标签3"
                        }
                    ]


                }
            },
            ],

        }
    },
    components: {
        'discuss-category': discussCategory,
        'user-data': userData,
        'tool-box': toolBox,
        'news': news
    },
    methods: {

        changeNavType(item) {
            if (item.id && this.navId !== item.id) {
                this.navId = item.id
            }
        },
        async toPublish(hash = '') {
           var data = {
                userId: 1,
                articleId: 0,
                areaId: 0,
                title: '',
                descContent: '',
                markContent: '',
                coverImage: '',
            }
            let res = await insertArticleDraft(data);
            let draftId = res.data.draftId;

            let routeUrl = this.$router.resolve({
                name: 'detail-id',
                params: {
                    id: this.detailId
                }
            })
            window.open(routeUrl.href + hash, '_blank')
            console.log(res);
        },

    }
}

</script>
<style lang="scss" scoped>
.body-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .index-container,
    .right-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .index-container {
        display: flex;
        position: relative;
        left: 148px;

        .index-main {
            width: 720px;
            min-height: 620px;
            margin-right: 20px;
            background: #fff;
            border-radius: 10px;
            padding: 20px;
        }

        .index-side {
            width: 240px;
        }

        margin-left: 12px;
    }

    .list__header {
        border-bottom: 1px solid $color-third;
    }

    .list__nav {
        display: flex;
        align-items: center;

        .publish {
            margin-left: auto;
            font-size: $font-size-Level6;
            padding: 4px 10px;
            background-color: $color-primary;

            border-radius: 5px;
            cursor: pointer;

            color: $font-color-secondary;
            background-color: $color-primary;

            span {
                font-weight: 900;
                font-size: $font-size-Level6;
                margin-right: 7px;
            }
        }

        .list__nav-item {
            height: 50px;
            display: inline-block;
            font-size: $font-size-Level5;
            color: $font-color-primary;
            cursor: pointer;
            padding: 0 23px;
            position: relative;


            line-height: 50px;

            &:hover,
            &.list__nav-item--active {
                color: $color-primary;
            }

            &.list__nav-item--active::after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 34px;
                height: 8px;
                background: $color-primary;
                left: 30%;
                transform: scaleY(.5);
            }

        }
    }

    .right-section {
        width: 300px;
        height: 620px;

        .user-data {
            height: 60%;
        }
    }
}
</style>