<template>
    <div class="user-data">
        <div class="user-info">
            <span class="version-tag">纯净版</span>
            <span class="nick-name">{{ this.$store.getters['user/getState'].nickName }}</span>
        </div>
        <div class="action-data">
            <div class="action-item-data">
                <div><span>2</span>小时<span>22</span>分钟</div>
                <div>今日已专注</div>
                <div>>95.3%</div>
            </div>
            <div class="action-item-data">
                <div><span>20</span>道</div>
                <div>累计做题</div>
                <div>>85.2%</div>
            </div>
            <div class="action-item-data">
                <div><span>20</span>道</div>
                <div>累计补充</div>
                <div>>85.1%</div>
            </div>
        </div>
        <div class="hot-data">
            <get-heat-map></get-heat-map>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
  
<style lang='scss' scoped>
.user-data {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 12px;

    .user-info {
        .version-tag {
            font-size: $font-size-Level7;
            color: $font-color-secondary;
            background-color: $background-color-third;
            border-radius: 2px;
            padding: 3px 5px;
            margin-right: 12px;
        }

        .nick-name {
            line-height: normal;
        }
    }

    .action-data {
        display: flex;

        justify-content: space-between;

        .action-item-data {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            div:first-child {
                margin-bottom: 4px;
                color: $font-color-fourth;
                font-size: $font-size-Level7;

                span {
                    font-size: $font-size-Level4;
                    color: $font-color-primary;
                    padding: 4px;
                }
            }

            div:nth-child(2) {
                margin-bottom: 4px;
                color: $font-color-fourth;
                font-size: $font-size-Level7;
                line-height: normal;
            }

            div:nth-child(3) {
                margin-bottom: 4px;
                color: $font-color-third;
                font-size: $font-size-Level7;
                line-height: normal;
            }
        }
    }

    .hot-data {
        height: 60%;
    }
}</style>