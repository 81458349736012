
<template>
    <div class="heat-map-wrapper">
        <div class="heat-map">
            <el-tooltip content="Top center" placement="top" v-for="(item, index) in data" :key="index" effect="light">
                <span class="heat-map__item" :class="'heat-map__item__level' + Math.ceil(item[2] / 5)">
                </span>
            </el-tooltip>
            <div class="x-axis">
            <div v-for="item in xSerials">{{ item }}</div>
        </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'TopBar',
    data() {
        return {
            xSerials: ["十月", "十一月", "十二月"],
            data: [[0, 0, 1], [0, 1, 1], [0, 2, 0], [0, 3, 0], [0, 4, 1], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 0]
                , [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 3], [1, 10, 0], [1, 11, 0]
                , [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 0], [2, 11, 0]
                , [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 7], [3, 8, 1], [0, 9, 0], [3, 10, 1], [0, 11, 0]
                , [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 8], [4, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 0]
                , [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [0, 9, 0], [0, 10, 0], [0, 11, 0]
                , [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 0]]
        }
    }
}
</script>

<style lang="scss" scoped>
.heat-map-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .heat-map {
        width: 254px;   
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        .heat-map__item {
            display: inline-block;
            width: 15px;
            height: 15px;
            border-radius: 2px;
            background: #EFEFEF;
            margin:auto 3px;
            &:hover {
                border: 1px solid $color-primary;
                transform: scale(1.2);
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 24px;
            }
        }

        .heat-map__item__level1 {
            background-color: #CCF1F1;
        }

        .heat-map__item__level2 {
            background-color: #79D6D3;
        }

        .heat-map__item__level3 {
            background-color: #46B4B1;
        }
    }

    .x-axis {
        margin-top: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        div {
            color: $font-color-fourth;
            font-size: $font-size-Level7;

        }
    }
}</style>
