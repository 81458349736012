export default {
    namespaced: true,
    state: {
        areaCategoryList: [
                {
                  areaId: 1,
                  content: "国家"
                },
                {
                  areaId: 2,
                  content: "安徽"
                },
                {
                  areaId: 3,
                  content: "北京"
                },
                {
                  areaId: 4,
                  content: "福建"
                },
                {
                  areaId: 5,
                  content: "甘肃"
                },
                {
                  areaId: 6,
                  content: "广东"
                },
                {
                  areaId: 7,
                  content: "广西"
                },
                {
                  areaId: 8,
                  content: "贵州"
                },
                {
                  areaId: 9,
                  content: "海南"
                },
                {
                  areaId: 10,
                  content: "河北"
                },
                {
                  areaId: 11,
                  content: "河南"
                },
                {
                  areaId: 12,
                  content: "黑龙江"
                },
                {
                  areaId: 13,
                  content: "湖北"
                },
                {
                  areaId: 14,
                  content: "湖南"
                },
                {
                  areaId: 15,
                  content: "吉林"
                },
                {
                  areaId: 16,
                  content: "江苏"
                },
                {
                  areaId: 17,
                  content: "江西"
                },
                {
                  areaId: 18,
                  content: "辽宁"
                },
                {
                  areaId: 19,
                  content: "内蒙古"
                },
                {
                  areaId: 20,
                  content: "宁夏"
                },
                {
                  areaId: 21,
                  content: "青海"
                },
                {
                  areaId: 22,
                  content: "山东"
                },
                {
                  areaId: 23,
                  content: "山西"
                },
                {
                  areaId: 24,
                  content: "陕西"
                },
                {
                  areaId: 25,
                  content: "上海"
                },
                {
                  areaId: 26,
                  content: "四川"
                },
                {
                  areaId: 27,
                  content: "天津"
                },
                {
                  areaId: 28,
                  content: "西藏"
                },
                {
                  areaId: 29,
                  content: "新疆"
                },
                // {
                //   areaId: 30,
                //   content: "新疆兵团"
                // },
                {
                  areaId: 31,
                  content: "云南"
                },
                {
                  areaId: 32,
                  content: "浙江"
                },
                {
                  areaId: 33,
                  content: "重庆"
                },
                // {
                //   areaId: 34,
                //   content: "广州"
                // },
                // {
                //   areaId: 35,
                //   content: "深圳"
                // },
                {
                  areaId: 36,
                  content: "选调"
                },
                {
                  areaId: 37,
                  content: "公安"
                }
        ]
    },
    getters: {
        getState(state) {
            return state;
        }
    },

    mutations: {
        setAreaCategoryList(state, data) {
            state.areaCategoryList = data;
        }
    },
    actions: {
        subAsy({ commit }, data) {
            setTimeout(res => {
                commit('areaCategoryList', data)
            }, 1000)
        }
    }
}