<template>
    <header class="topbar__wrapper">
        <div class="topbar topbar--show">
            <div class="topbar__main">
                <div class="logo">
                    <router-link to="/community/home">
                        <img src="@/assets/images/logo.png" alt="">
                        <img src="@/assets/images/logo-font.png" alt="">
                    </router-link>
                </div>
                <!-- 导航功能 -->
                <div class="navs">
                    <ul class="navs">
                        <router-link v-for="item in navs" :key="item.name" :to="item.link" class="nav-item">{{
                            item.name }}</router-link>
                    </ul>
                </div>
                <div class="search">
                    <input type="text" placeholder="搜索 内容/工具/用户" />
                    <img src="@/assets/images/search.svg" alt="">
                </div>
                <div class="notice">
                    <img src="@/assets/images/notice.svg" alt="">
                </div>
                <div class="user-avatar">
                    <user-avatar :url="this.$store.getters['user/getState'].avatar" :round="true"></user-avatar>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'TopBar',
    data() {
        return {
            navs: [
                {
                    name: '社区杂谈',
                    link: '/community/home'
                },
                {
                    name: '近日闲聊',
                    link: '/community/chat'
                },
                {
                    name: '争议题讨论',
                    link: '/community/argue'
                },
            ],
        }
    },
    methods: {
        
    }
}
</script>
<style scoped lang="scss">
.topbar__wrapper {
    z-index: 1000;
    position: relative;
    height: 60px;
}

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 4px #eee;
    transition: all .2s;
    &.topbar--show {
        transform: translateY(0);
    }
    &.topbar--hidden {
        transform: translateY(-100%);
    }
}

.topbar__main {
    display: flex;
    align-items: center;
    max-width: 1280px;
    padding: 0 40px;
    height: 100%;
    margin: 0 auto;
    .logo {
        margin-right: 20px;
        cursor: pointer;
        img:first-child {
            width: 32px;
            margin-right: 16px;
        }

        img:last-child {
            width: 108px;
            margin-right: 56px;
        }
    }
}

.navs {
    display: flex;
    line-height: 60px;
    font-size: $font-size-Level6;
    color: $font-color-fourth;
    .nav-item {
        padding: 0 20px;
        cursor: pointer;
        &:hover,&.router-link-active {
            color: $color-primary;
        }
    }
    
}

.search {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;
    input {
        width: 260px;
        height: 28px;
        border-radius: 28px;
        border: 1px solid $color-third;
        padding: 0 20px;
        &:focus {
            border: 1px solid $color-primary;
        }
    }

    img {
        position: absolute;
        right: 20px;
        cursor: pointer;
    }
}

.notice {
    margin-left: auto;
    margin-right: 18px;
    cursor: pointer;
}

.user-avatar {
    cursor: pointer;
    width: 32px;
    height: 32px;
}
</style>