<template>
    <ul class="nav-list">
        <li class="nav-item">
            <el-checkbox-group v-model="checkPaper">
                <el-checkbox label="公务员考试"></el-checkbox>
                <el-checkbox label="事业单位考试"></el-checkbox>
            </el-checkbox-group>
        </li>
        <li class="nav-item" :class="{ 'nav-item--active': checkList.length == 0 }" @click="selectedCategory()" >
            综合
        </li>
        <li class="nav-item" :class="{ 'nav-item--active': checkList.includes(item.areaId) }"  v-for="item in areaList" :key="item.areaId"
            :title="item.content" @click="selectedCategory(item)">
            {{ item.content }}
        </li>
    </ul>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            checkPaper: ["公务员考试"],
            checkList:[],
        }
    },
    computed: {
        ...mapState('category',{
            areaList: 'areaCategoryList'
    })
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        selectedCategory(item) {
            if(item) {
                if(!this.checkList.includes(item.areaId)) {
                this.checkList.push(item.areaId);
                } else {
                    this.checkList.splice(this.checkList.indexOf(item.areaId), 1);
                }
            } else {
                this.checkList = [];
            }
        }
    }
}
</script>
<style lang='scss' scoped>
.nav-list {
    width: 148px;
    min-height: 620px;
    height: auto;
    position: fixed;
    padding: 12px 8px;
    background-color: $background-color;
    border-radius: 10px;
    transition: all .2s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .nav-item {
        position: relative;
        width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $font-color-primary;
        border-radius: 3px;
        transition: background-color .2s, color .2s;
        cursor: pointer;
        &:nth-of-type(2)::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #ddd;
            transform: scaleY(.5);
            margin-bottom: 5px;
        }
        &:not(:first-child):not(:nth-of-type(2)) {
            margin-top: 5px;
        }
        &:first-child {
            width: 100%;
            /deep/.el-checkbox-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
            // 自定义element-ui多选框样式
            /deep/.el-checkbox  {
                margin-right: 0px;
            }
            /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
                color: $color-primary;
            }
            /deep/ .el-checkbox__inner {
                margin: 4px 0;
                border-color: $color-primary;
            }
            /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                border-color: $color-primary !important;
                background-color: $color-primary !important;
            }
        }
        &:nth-child(2) {
            font-size: $font-size-primary;
            flex-basis: 100%;
        }
        &:not(:first-child):hover {
            background-color: $background-color-secondary;
            color: $color-primary;
        }
    }
    .nav-item--active,.nav-item--active:hover {
        background-color: $background-color-fourth !important;
        color: $color-primary;
    }
}
</style>
