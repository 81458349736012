<template>
  <div id="app">
    <top-bar v-if="module === 'community'"></top-bar>
    <main v-if="module === 'community'" class="main"><router-view></router-view></main>
    <router-view v-else></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      module: ''
    }
  },
  components: {

  },
  watch: {
    '$route'(to, from) {
      // 当路由发生变化时执行此函数
      this.routePath = to.path;
      if (this.routePath.indexOf("community") > 0) {
        this.module = "community"
      } else {
        this.module = "home"
      }
      // 在这里添加你的处理逻辑
    },
  },
  created() {
    // 路由发生变化时执行
    this.routePath = this.$route.path;
    if (this.routePath.indexOf("community") > 0) {
      this.module = "community"
    }
  },
}
</script>

<style lang="scss" scoped>
#app {
  background-color: $background-color-secondary;
  min-height: 100vh;
}

.main {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
}

#nprogress .bar {
  background: $color-primary !important;
}
</style>
