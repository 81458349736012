import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/home/index.vue';
import CodeLoginPage from '@/views/login/code/index.vue';
import BindLoginPage from '@/views/login/bind/index.vue';
import AccountLoginPage from '@/views/login/account/index.vue';
import ForgetPasswordPage from '@/views/login/forget/index.vue';
import CommunityHomePage from '@/views/community/home/index.vue';
import CommunityChatPage from '@/views/community/chat/index.vue';
import CommunityArguePage from '@/views/community/argue/index.vue';
import CommunityDetailPage from '@/views/community/detail/index.vue';
import CommunityPublishPage from '@/views/community/publish/index.vue';
import CommunityUserPage from '@/views/community/user/index.vue';
import WxServeLoginPage from '@/wx-serve/login/index.vue';
import WxServeVersionPage from '@/wx-serve/version/index.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'home',
        component: HomePage,
        meta: {
            title: '首页'
        }
    },
    {
        path: '/login/code',
        component: CodeLoginPage,
        meta: {
            title: '二维码登录'
        }
    },
    {
        path: '/login/bind',
        component: BindLoginPage,
        meta: {
            title: '绑定账户'
        }
    },
    {
        path: '/login/account',
        component: AccountLoginPage,
        meta: {
            title: '账号登录'
        }
    },
    {
        path: '/login/forget',
        component: ForgetPasswordPage,
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/community/home',
        component: CommunityHomePage,
        meta: {
            title: '社区首页'
        }
    },
    {
        path: '/wx-serve/login',
        component: WxServeLoginPage,
        meta: {
            title: ''
        }
    },
    {
        path: '/wx-serve/version',
        component: WxServeVersionPage,
        meta: {
            title: '开通会员'
        }
    },
    {
        path: '/community/chat',
        component: CommunityChatPage,
        meta: {
            title: '近日闲聊'
        }
    },
    {
        path: '/community/argue',
        component: CommunityArguePage,
        meta: {
            title: '争议题讨论'
        }
    },
    {
        path: '/community/detail/:id',
        component: CommunityDetailPage,
        name: "detail-id"
    },
    {
        path: '/community/user/:id',
        component: CommunityUserPage,
        name: "user-id"
    },
    {
        path: '/community/publish',
        component: CommunityPublishPage,
        meta: {
            title: '内容发布'
        }
    },
];

const route = new VueRouter({
    routes,
    mode: 'history'
})

export default route;