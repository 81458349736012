<template>
    <ul class="nav-list">
        <li class="nav-item" :class="{ 'nav-item--active': checkType === item.type }"  v-for="item in categoryList" :key="item.type"
            :title="item.content" @click="selectedCategory(item)">
            {{ item.content }}
        </li>
    </ul>
</template>
<script>
export default {
    data() {
        return {
            checkType: 1,
            categoryList: [{
                content: '最新',
                type: 1
            },{
                content: '最热',
                type: 2
            },
            {
                content: '申论',
                type: 3
            },
            {
                content: '行测',
                type: 4
            }],
        }
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {

    }
}
</script>
<style lang='scss' scoped>
.nav-list {
    width: 148px;
    min-height: 620px;
    height: auto;
    position: fixed;
    padding: 12px 8px;
    background-color: $background-color;
    border-radius: 10px;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    &.nav-list--sticky {
        top: 20px;
    }

    .nav-item {
        position: relative;
        width: 100%;
        display: flex;
        padding: 7px 50px;
        align-items: center;
        justify-content: center;
        color: $font-color-primary;
        border-radius: 3px;
        transition: background-color .2s, color .2s;
        font-size: 16px;
        cursor: pointer;
        &:nth-of-type(2) {
            margin-top: 8px;
        }
    }

    .nav-item--active,
    .nav-item--active:hover {
        background-color: $background-color-fourth !important;
        color: $color-primary;
    }
}
</style>
