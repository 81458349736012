<template>

</template>
<script>

export default {
  data() {
    return {}
  },
  methods: {},
  mounted() {
    // 微信授权页面跳转
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize' +
        '?appid=wx34c698658f0be747' + // 替换为你的 回调地址
        '&redirect_uri=' + encodeURIComponent('https://srnz.net/wx-serve/version') +
        '&response_type=code' +
        '&scope=snsapi_userinfo' +
        '&state=0816' + // 可自定义，防止CSRF攻击
        '#wechat_redirect';
  }
}
</script>
<style lang="scss" scoped>
</style>