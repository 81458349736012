<template>
    <div class="news-wrapper">
        <div class="news-title">
            <div><img src="@/assets/images/news.svg" alt="">最新资讯</div>
            <div class="see-more">查看更多></div>
        </div>
        <ul class="news-item-list">
            <li class="news-item">
                四川省发布最新考试公告！招录20人，一共19人通过考试
            </li>
            <li class="news-item">
                四川省发布最新考试公告！招录20人，一共19人通过考试
            </li>
            <li class="news-item">
                四川省发布最新考试公告！招录20人，一共19人通过考试
            </li>
        </ul>
        
    </div>
</template>
  
<script>
export default {
    props: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
  
<style lang='scss' scoped>
.news-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $background-color;
    border-radius: 10px;
    padding: 8px;
    

    .news-title {
        display: flex;
        justify-content: space-between;
        img {
            width: 24px;
            margin-right: 6px;
        }
    }

    .news-item-list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        list-style-type: decimal !important;
        list-style-position: inside;
        position: relative;
        .news-item {
            font-size: $font-size-Level7;
            padding: 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            cursor: pointer;
            border-radius: 4px;
            &:hover {
                background-color: $background-color-secondary;
            }
        }
    }
    .see-more {
        cursor: pointer;
        color: $font-color-fourth;
    }

}</style>