<template>
    <div class="user-data">
        <div class="user-info">
            <div class="avatar">
                <user-avatar :url="this.$store.getters['user/getState'].avatar" :round="true"></user-avatar>
            </div>
            <div class="info">
                <span class="nick-name">{{ this.$store.getters['user/getState'].nickName }}</span>
                <div class="tags">
                    <span class="tag">标签1</span>
                    <span class="tag">标签2</span>
                    <span class="tag">标签3</span>
                </div>
                <span class="user-name-desc">个性签名</span>
            </div>
            <div class="action-area">
                <follow-btn></follow-btn>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
  
<style lang='scss' scoped>
.user-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $background-color;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 12px;

    .user-info {
        display: flex;

        .avatar {
            flex: 0 0 auto;
            width: 90px;
            height: 90px;
            margin-right: 36px;
        }

        .info {
            width: 100%;
            display: flex;
            flex-direction: column;

            .nick-name {
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                color: #000;
            }

            .tags {
                margin-top: 16px;
                display: flex;
                justify-content: flex-start;

                .tag {
                    padding: 0 12px;
                    font-size: 12px;
                    color: #515767;
                    margin-right: 16px;
                    background-color: #F7F8FA;
                }
            }

            .user-name-desc {
                color: #8A919F;
                font-size: 16px;
                margin-top: 16px;
            }
        }
    }

    .action-area {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
}
</style>