<template>
  <div class="container">
    <div class="page-body">
      <div class="version-info">
        <div class="version-title">
          纯净版
          <div class="line"></div>
        </div>
        <div class="version-desc">
          您的小小支持能为小囊带来无限动力~
        </div>
        <div class="price-wrapper">
          <div v-for="(item, index) in versionMealList" :key="index" @click="handleSelect(index)"
               :class="selected == index ? 'price-card active' : 'price-card'">
            <div :class="selected == index ? 'period active' : 'period'">{{ item.period }}个月</div>
            <div :class="selected == index ? 'price active' : 'price'">￥{{ item.currentPrice }}</div>
            <div v-if="index == 1" style="text-decoration: none;"
                 :class="selected == index ? 'desc active' : 'desc'">0.16元/天
            </div>
            <div v-else :class="selected == index ? 'desc active' : 'desc'">￥{{ item.originalPrice }}
            </div>
          </div>
        </div>
        <img class="about-version" src="../../assets/images/about-version.png" alt=""/>
        <div class="oper-area">
          <div class="bottom-line"></div>
          <div class="button-version" @click="openVersion">
            <div>
              ￥{{ payAmount }}
            </div>
            <div>
              立即开通
            </div>
          </div>
          <!-- <div class="server-desc">订阅即视为同意我们的购买须知和应用政策</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getWxServeAccessToken,
  login,
  getVersionMealList,
  createOrder,
  activePay,
  appPayConfig
} from '@/api';
import wx from "weixin-js-sdk";
import {setToken} from "@/utils/auth";

export default {
  data() {
    return {
      selected: 1,
      pageNum: 1,
      pageSize: 100,
      versionMealList: [],
      payAmount: 0,
      openid: '',
    }
  },
  methods: {
    handleSelect(index) {
      this.selected = index,
          this.payAmount = this.versionMealList[index].currentPrice
    },
    getVersionMeals(params) {
      params.pageSize = this.pageSize;
      params.pageNum = this.pageNum;
      params.versionId = 1;
      getVersionMealList(params).then(response => {
        this.versionMealList = response.rows;
        this.payAmount = response.rows[this.selected].currentPrice;
      });
    },
    async openVersion() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        payAmount: this.payAmount,
        productType: 1, // 会员
        mealId: this.versionMealList[this.selected].mealId
      }
      let createOrderResponse = await createOrder(data);
      if (createOrderResponse.code != 200) {
        this.$message.error('创建订单失败');
        return;
      }
      data = createOrderResponse.data;
      data.platform = 2;
      data.appId = "wx34c698658f0be747";
      data.openid = this.openid;
      let activePayResponse = await activePay(data);
      if (activePayResponse.code != 200) {
        this.$message.error('订单激活失败');
        return;
      }
      data = {
        prepayId: activePayResponse.data,
        type: "wxServe"
      }
      let appConfigResponse = await appPayConfig(data);
      console.log(appConfigResponse);
      if (appConfigResponse.code != 200) {
        this.$message.error('支付配置异常');
        return;
      }
      loading.close();
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来,线上环境需要改为false
        appId: 'wx34c698658f0be747', // 必填，公众号的唯一标识
        timestamp: appConfigResponse.data.timeStamp, // 必填，生成签名的时间戳
        nonceStr: appConfigResponse.data.nonceStr, // 必填，生成签名的随机串
        signature: appConfigResponse.data.paySign, // 必填，签名
        jsApiList: ['chooseWXPay'], // 必填，需要使用的JS接口列表
      });
      wx.ready(() => {
        wx.chooseWXPay({
          //下面参数要与上面对应
          appId: 'wx34c698658f0be747', // 必填，公众号的唯一标识
          timestamp: appConfigResponse.data.timeStamp,
          nonceStr: appConfigResponse.data.nonceStr, // 支付签名随机串，不长于 32 位
          package: appConfigResponse.data.packageValue, // 统一支付接口返回的prepay_id参数值
          signType: appConfigResponse.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: appConfigResponse.data.paySign, // 支付签名
          success: function (res) { //支付成功回调
            console.log('支付成功')
            this.$message.success('支付成功');
          },
          cancel: function (res) {
            console.log('取消支付')
            this.$message.warning('取消支付');
          },
          fail: function (res) {
            console.log("支付失败", res);
            this.$message.error('取消支付');
          }
        });
      });
    },
  },
  async mounted() {
    if(this.$route.query.code) {
      let params = {
        code: this.$route.query.code,
        state: this.$route.query.state
      }
      // 根据code获取token
      let accessTokenResponse = await getWxServeAccessToken(params);
      if(accessTokenResponse.code != 200 || accessTokenResponse.data.errcode) {
        this.$message.error('微信授权异常');
        return;
      }
      let unionid = accessTokenResponse.data.unionid;
      this.openid = accessTokenResponse.data.openid;
      let loginResponse = await login({
        account: unionid,
        type: 1 // 通过微信账号登录
      });
      if(loginResponse.code != 200) {
        this.$message.error('登录异常');
        return;
      }
      setToken(loginResponse.token);
      this.getVersionMeals({});
    } else {
      this.$router.push('/wx-serve/login');
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.page-body {
  display: flex;
  flex-direction: column;
  padding: 0px 16.5px;
  height: 96vh;
  overflow: hidden;
  position: relative;
}

.version-info {
  width: 100%;
  margin-top: 10px;
  height: 100%;
  position: relative;
}

.version-title {
  position: relative;
  display: inline-block;
  color: #000;
  font-family: "PingFang SC";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 51px;
  padding: 0 10px;
  margin-left: 10px;
}

.version-title .line {
  position: absolute;
  background-color: #46B4B1;
  width: 90px;
  height: 10px;
  border-radius: 4px;
  bottom: 10px;
  left: 0px;
  z-index: -10;
}

.version-desc {
  color: #000;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
  margin-left: 10px;
}

.price-wrapper {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}

.price-wrapper .price-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  border-radius: 10px;
  border: 2px solid #ECECEC;
  background: #F6F6F6;
  padding: 9px 14px 0px 14px;
}

.price-wrapper .active {
  border-color: #46B4B1 !important;
  background-color: #EAF8F7 !important;
  color: #46B4B1 !important;
}

.price-wrapper .price-card .period {
  color: #787878;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 200% */
}

.price-wrapper .price-card .price {
  margin-top: 4px;
  color: #333;
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
}

.price-wrapper .price-card .desc {
  margin-top: 13px;
  color: #787878;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-decoration: line-through;
  /* 233.333% */
}

.about-version {
  width: 100%;
}

.bottom-line {
  width: 100%;
  height: 1px;
  background-color: #D8D8D8;
}

.oper-area {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-version {
  background: #46B4B1;
  border-radius: 20px;
  color: #FFF;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 175% */
  padding: 6px 40px;
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}

.server-desc {
  color: #BFBFBF;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 280% */
  text-decoration-line: underline;
}
</style>