<template>
    <div class="body-content">
        <chat-category></chat-category>
        <div class="index-container">
            <div class="index-main shadow">
                <div class="publish-area">
                    <publish-comment placeholder="快和囊友一起分享新鲜事！" :inputCount="400"></publish-comment>
                </div>
                <div class="publish-item-wrap shadow" v-for="(item, index) in publishList" :key="item.msg_id">
                    <!-- <publish-item :item.sync="publishList[index]" :actors="actors"></publish-item> -->
                </div>
            </div>
        </div>
        <div class="right-section">
            <about-author class="about-author" :info="articDetail" :showInfo="false" :showFollowbtn="false"></about-author>
            <tool-box class="tool-box"></tool-box>
        </div>
    </div>
</template>
<script>
import chatCategory from '@/components/business/chat/chatCategory.vue';
import aboutAuthor from '@/components/business/detail/aboutAuthor.vue';
import toolBox from '@/components/business/discuss/toolBox.vue';
export default {
    name: "CommunityHomePage",
    data() {
        return {
            showInfo: false,
            showFollowbtn: false,
            articDetail: {
                author_user_info: {
                    user_id: 1
                },
                author_user_info: {
                    avatar_large: '',
                    user_name: "测试",
                    level: 10,
                    job_title: '后端',
                    company: '微亮'
                },
                article_info: {
                    brief_content: '1. 敢于超前研发。树立首创精神,敢为人先,形成差异化竞争力,抵御风险,赢得优势。 2. 加强技术攻关。成立专门团队,进行大量试验,不懈探索分析,不断尝试改进。 3. 市场需求导',
                    ctime: '2023-10-26 17:23',
                    view_count: 100,
                    cover_image: '',
                    title: "这是测试标题",

                },
                user_interact: {
                    is_follow: false
                },
                tags: [{
                    tag_id: 1,
                    tag_name: 'ce'
                },
                {
                    tag_id: 2,
                    tag_name: 'ae'
                },
                {
                    tag_id: 3,
                    tag_name: 'se'
                }]
            },
            actors:[
                {
                    id:1,
                    avatarLarge: '',
                    username: 'daxi',
                    jobTitle: '开发工程师',
                    company: '微亮'
                }
            ],
            publishList:[
                {
                    title: '这是标题',
                    content: '这是内容',
                    screenshot: '@/assets/images/logo.png',
                    author_user_info: {
                        user_id: 1,
                        job_title: '测试工程师',
                        company: '微软'
                    },
                    msg_Info: {
                        ctime: '2小时前',
                        content: '这只是浅浅发布一下内容',
                        pic_list:['@/assets/images/logo.png',],
                        digg_count: 100,
                        comment_count: 100,
                        url_title: '百度官网',
                        url: 'www.baidu.com',
                        url_pic: '@/assets/images/logo.png',
                    },
                    topic: {
                        topic_id: 1,
                        title: '自定义话题'
                    },
                    
                    user_interact: {
                        is_follow: true,
                        is_digg: false,
                    }
                },
                {
                    title: '这是标题',
                    content: '这是内容',
                    screenshot: '@/assets/images/logo.png',
                    author_user_info: {
                        user_id: 1,
                        job_title: '测试工程师',
                        company: '微软'
                    },
                    msg_Info: {
                        ctime: '2小时前',
                        content: '这只是浅浅发布一下内容',
                        pic_list:['@/assets/images/logo.png','@/assets/images/logo.png'],
                        digg_count: 100,
                        comment_count: 100,
                        url_title: '百度官网',
                        url: 'www.baidu.com',
                        url_pic: '@/assets/images/logo.png',
                    },
                    topic: {
                        topic_id: 1,
                        title: '自定义话题'
                    },
                    
                    user_interact: {
                        is_follow: true,
                        is_digg: false,
                    }
                },
                {
                    title: '这是标题',
                    content: '这是内容',
                    screenshot: '@/assets/images/logo.png',
                    author_user_info: {
                        user_id: 1,
                        job_title: '测试工程师',
                        company: '微软'
                    },
                    msg_Info: {
                        ctime: '2小时前',
                        content: '这只是浅浅发布一下内容',
                        pic_list:['@/assets/images/logo.png','@/assets/images/logo.png','@/assets/images/logo.png'],
                        digg_count: 100,
                        comment_count: 100,
                        url_title: '百度官网',
                        url: 'www.baidu.com',
                        url_pic: '@/assets/images/logo.png',
                    },
                    topic: {
                        topic_id: 1,
                        title: '自定义话题'
                    },
                    
                    user_interact: {
                        is_follow: true,
                        is_digg: false,
                    }
                }
            ]
        }
    },
    components: {
        'chat-category': chatCategory,
        'about-author': aboutAuthor,
        'tool-box': toolBox,
    },
    methods: {

        changeNavType(item) {
            if (item.id && this.navId !== item.id) {
                this.navId = item.id
            }
        }
    }
}

</script>
<style lang="scss" scoped>
.body-content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .index-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .index-container {
        display: flex;
        position: relative;
        left: 148px;

        .index-main {
            width: 720px;
            min-height: 620px;
            margin-right: 20px;
            .publish-area {
                padding: 16px;
                background-color: #FFF;
                border-radius: 10px;
            }
        }
        margin-left: 12px;
    }
    .right-section {
        width: 300px;
        .tool-box {
            margin-top: 16px;
        }
    }
}
</style>